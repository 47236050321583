import { HeadProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { DirectionalityService } from '../../services';
import { EntryData } from '../../templates/types';

/**
 * @returns Head API specific component
 *
 * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#additional-resources
 */
export const Head = (props: HeadProps) => {
  const { lang } = (props.pageContext as EntryData).story;
  return (
    <html dir={DirectionalityService.getDirectionality(lang)} />
  );
};

interface HelmetHeadProps {
  lang?: string
}

/**
 * Use only for dynamic pages. (ex: src/pages/editor.tsx, ExternalData template component)
 * @returns React Helmet based "head" attributes and tags.
 */
export const HelmetHead = (props: HelmetHeadProps) => (
  <Helmet
    htmlAttributes={{
      dir: DirectionalityService.getDirectionality(props.lang),
    }} />
);
