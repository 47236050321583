import React from 'react';
import { Helmet } from 'react-helmet';
import { FontOverwrites } from '../../templates/types';

interface FontOverwritesProps {
  overrides: FontOverwrites;
}

const getFontOverride = (key: string, value: string): string => {
  const overrides = {
    'primary-bold': `
      @font-face {
        font-family: 'roche-sans';
        src: url(${value}) format('woff2');
        font-weight: 800;
        font-style: normal;
    }`,
    'primary-medium': `
      @font-face {
        font-family: 'roche-sans';
        src: url(${value}) format('woff2');
        font-weight: 600;
        font-style: normal;
    }`,
    'primary-regular': `
      @font-face {
        font-family: 'roche-sans';
        src: url(${value}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }`,
    'primary-light': `
      @font-face {
        font-family: 'roche-sans';
        src: url(${value}) format('woff2');
        font-weight: 200;
        font-style: normal;
    }`,
    'secondary-regular': `
      @font-face {
        font-family: 'roche-serif';
        src: url(${value}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }`,
    'secondary-light': `
      @font-face {
        font-family: 'roche-serif';
        src: url(${value}) format('woff2');
        font-weight: 200;
        font-style: normal;
    }`,
  };

  return overrides[key] || '';
};

export const FontOverride = ({ overrides }: FontOverwritesProps): JSX.Element => {
  if (!overrides) {
    return null;
  }

  const fontFaceDeclarations = Object
    .entries(overrides)
    .map(([key, value]) => getFontOverride(key, value))
    .join('')
    .replace(/\s+/g, '');

  return <Helmet style={[{ cssText: fontFaceDeclarations }]} />;
};
