/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { replaceSubdomain } from '../../utils/replace-subdomain';

type SEOProps = {
  authorized_roles?: string[];
  contentType?: 'pages' | 'stories';
  description?: string;
  first_published_at?: string;
  lang?: string;
  og_description?: string;
  og_image?: string;
  og_title?: string;
  slug?: string;
  story_type?: string;
  teaser_image?: string;
  title?: string;
  twitter_description?: string;
  twitter_image?: string;
  twitter_title?: string;
  teaser_title?: string;
  teaser_description?: string;
  releaseType?: 'media' | 'investors';
  releaseDate?: string;
};

export function SEO({
  title,
  description,
  lang,
  slug,
  contentType,
  /* TODO: Standardise naming convention of component props to use camelCase */
  /* eslint-disable @typescript-eslint/naming-convention */
  og_image,
  og_title,
  og_description,
  twitter_image,
  twitter_title,
  twitter_description,
  authorized_roles,
  story_type,
  first_published_at,
  teaser_image,
  teaser_title,
  teaser_description,
  releaseType,
  releaseDate,
  /* eslint-enable @typescript-eslint/naming-convention */
}: SEOProps): JSX.Element {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            url
            defaultLanguage
            twitterHandle
          }
        }
      }
    `,
  );

  const isPrivate = authorized_roles?.includes('authorized') ? 'yes' : 'no';
  const websiteUrl = replaceSubdomain(process.env.GATSBY_WEBSITE_URL);
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || teaser_title || og_title}
      meta={[
        title && { name: 'title', content: title },
        description && { name: 'description', content: description },
        og_image && { property: 'og:image', content: og_image },
        (og_title || title) && { property: 'og:title', content: og_title || title },
        (og_description || description) && { property: 'og:description', content: og_description || description },
        { property: 'og:type', content: 'website' },
        slug && { property: 'og:url', content: `${websiteUrl}/${slug}` },
        twitter_image && { name: 'twitter:image', content: twitter_image },
        { name: 'twitter:card', content: 'summary' },
        site.siteMetadata.twitterHandle && { name: 'twitter:creator', content: site.siteMetadata.twitterHandle },
        twitter_title && { name: 'twitter:title', content: twitter_title },
        twitter_description && { name: 'twitter:description', content: twitter_description },
        { name: 'private', content: isPrivate },
        contentType && { name: 'content-type', content: contentType },
        story_type && { name: 'story-type', content: story_type },
        first_published_at && { name: 'published-date', content: first_published_at },
        teaser_image && { name: 'teaser-image', content: teaser_image },
        teaser_title && { name: 'teaser-title', content: teaser_title },
        teaser_description && { name: 'teaser-description', content: teaser_description },
        releaseType && { name: 'release-type', content: releaseType },
        releaseDate && { name: 'release-date', content: releaseDate },
      ].filter(Boolean) as { name?: string; property?: string; content: string }[]}
    />
  );
}
