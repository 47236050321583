/* eslint-disable filenames/match-regex */
import React from 'react';
import { InvestorsUpdatesDetail } from '../../../components/view/investors-updates-detail';
import { ExternalData } from '../../../templates/external-data';
import { ExternalDataProps } from '../../../templates/types';

const InvestorUpdatesDetailPage = (props: ExternalDataProps) => <ExternalData>
  <InvestorsUpdatesDetail {...props} />
</ExternalData>;

// eslint-disable-next-line import/no-default-export
export default InvestorUpdatesDetailPage;
