import React, { Component } from 'react';
import StoryblokClient, { StoryData } from 'storyblok-js-client';
import { Helmet } from 'react-helmet';
import { DatalayerItem, TrackingService } from '@rocheglobal/component-library-dot-com/src/services/tracking';
import { blokToComponent, getComponent } from '../components';
import {
  DomService, EditorService, LanguageService, NavigationService, StoryblokService,
} from '../services';
import { ExternalDataState } from './types';
import { Analytics } from '../components/custom/analytics';
import { ScrollTracking } from '../components/custom/scroll-tracking';
import { Hotjar } from '../components/custom/hotjar';
import { MetaRobotsContent } from '../components/custom/meta-robots-content';
import { HelmetHead } from '../components/custom/head';
import { retry } from '../utils/retry';
import { AdobeTracking } from '../tracking';

const mockedStory = {
  uuid: 'detail-page',
  content: {
    component: 'page',
    _uid: 'detail-page',
    hide_share: false,
  },
  alternates: [],
  created_at: '',
  full_slug: '',
  group_id: '',
  id: -1,
  is_startpage: false,
  meta_data: '',
  name: 'Detail Page',
  parent_id: null,
  position: 0,
  published_at: null,
  first_published_at: null,
  slug: 'detail',
  sort_by_date: null,
  tag_list: [],
};

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;
const Navigation = getComponent('navigation') as React.ElementType;
const MobileNavigation = getComponent('navigation-mobile') as React.ElementType;
const OffCanvas = 'roche-offcanvas-panel' as React.ElementType;

export class ExternalData extends Component<Record<string, unknown>, ExternalDataState> {
  private storyblokClient: StoryblokClient;

  private mutationObserverCallback: MutationCallback = () => this.setState({
    ...this.state,
    isDomReady: document.documentElement.lang === this.state.locale,
  });

  private observer: MutationObserver;

  public constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
    this.trackPageView = this.trackPageView.bind(this);

    this.state = {
      isLoading: true,
      isDomReady: false,
    } as ExternalDataState;
  }

  public async componentDidMount(): Promise<void> {
    this.observer = new MutationObserver(this.mutationObserverCallback);
    this.observer.observe(document.documentElement, { attributes: true });
    this.trackPageView();

    this.storyblokClient = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });
    await this.loadGlobalData();
    DomService.activateConsentScript();
  }

  public componentWillUnmount(): void {
    this.observer.disconnect();
  }

  private async loadGlobalData(): Promise<void> {
    const queryParams = {
      version: 'published',
      resolve_relations: StoryblokService.getConfig().options?.resolveRelations?.split(',') || [],
      resolve_links: StoryblokService.getConfig().options?.resolveLinks || '',
    } as unknown;

    const lang = LanguageService.getActiveLanguage();
    const locales = await retry(() => LanguageService.getAllLocals(this.storyblokClient), 5, 100);

    await EditorService.loadGlobalComponents(
      this.storyblokClient,
      (...args) => this.setState(
        ...args,
        () => {
          const {
            content: {
              emergency_banner: emergencyBannerUuid,
              footer: footerUuid,
              home: homeUuid,
              navigation: navigationUuid,
              on_click_notice: onClickNoticeUuid,
              search: searchUuid,
            },
          } = this.state.globalComponents;

          [
            [emergencyBannerUuid, 'emergencyBanner'],
            [footerUuid, 'footer'],
            [homeUuid, 'home'],
            [navigationUuid, 'navigation'],
            [onClickNoticeUuid, 'onClickNotice'],
            [searchUuid, 'search'],
          ]
            .forEach(([uuid, stateKeyName]) => EditorService.loadGlobalComponent(
              uuid,
              stateKeyName,
              this.storyblokClient,
              this.setState,
              queryParams,
              lang,
            ));
        },
      ),
    );

    this.setState({
      ...this.state,
      ...DomService.getGlobalConfig(
        {
          ...mockedStory,
          allSpaceLocales: locales,
          lang,
        } as StoryData,
      ),
      isLoading: false,
    });
  }

  public render(): JSX.Element {
    const {
      emergencyBanner,
      footer,
      onClickNotice,
      home,
      isLoading,
      isDomReady,
      locale,
      navigation,
      ...globalConfig
    } = this.state;
    const searchLink = navigation?.content?.global_search_link;
    const canShowStockPrice = !home?.content.hide_stock_price;
    const disableIndexing = process.env.GATSBY_BLOCK_SE_INDEXING === 'true' ?? false;

    return (
      <>
        <Helmet title='Roche - Doing now what patients need next' htmlAttributes={{ lang: locale }} />
        <HelmetHead lang={locale} />
        {
          (isDomReady && !isLoading) && <>
            <ScrollTracking data={this.state} />
            <Hotjar />
            {disableIndexing && <MetaRobotsContent />}
            <Analytics />
            <RocheGlobalConfig locale={locale} {...globalConfig}></RocheGlobalConfig>
            <OffCanvas id="roche-offcanvas-menu">
              <MobileNavigation
                tree={[
                  (navigation?.content?.primary_navigation || []),
                  (navigation?.content?.secondary_navigation || []),
                ]}
                worldWideLink={navigation?.content?.roche_world_wide_link}
              />
            </OffCanvas>
            <roche-header
              home-url={LanguageService.getHomePageUrl(home)}
              global-search-link={NavigationService.getParsedLink(searchLink)
                ? NavigationService.getParsedNavigationLink(searchLink)
                : null}
            >
              <Navigation tree={navigation?.content?.primary_navigation} slot="primary"></Navigation>
              <Navigation tree={navigation?.content?.secondary_navigation}
                slot="secondary"
                worldWideLink={navigation?.content?.roche_world_wide_link}
                globalSearchLink={NavigationService.getParsedLink(searchLink) ? searchLink : null}
                alternates={this.state.alternates}
              ></Navigation>
            </roche-header>
            {React.Children.map(
              this.props.children,
              (child: React.ReactElement) => React.cloneElement(child, { locale }),
            )}
            {footer
              && blokToComponent({ blok: { ...footer.content, canShowStockPrice }, getComponent })}
            {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
            {emergencyBanner && blokToComponent({ blok: emergencyBanner.content, getComponent })}
            <roche-fouc-preventer />
          </>
        }
      </>
    );
  }

  private trackPageView(): void {
    TrackingService.push({
      component_name: '',
      event: 'page_view',
      additionalParameters: {
        page_type: 'External-Data',
      },
    } as DatalayerItem);

    window.addEventListener('onetrustInitialized', () => {
      AdobeTracking(this.state, 'consentUpdate');
    });

    // Polling function to check when isDomReady is true before calling AdobeTracking
    const waitForDomReady = (callback: () => void) => {
      const checkDomReady = setInterval(() => {
        if (this.state.isDomReady) {
          clearInterval(checkDomReady); // Stop the interval once it's ready
          callback(); // Execute the callback when DOM is ready
        }
      }, 100);
    };

    waitForDomReady(() => {
      AdobeTracking(this.state, 'defaultPageView');
    });
  }
}
